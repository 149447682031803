import React, {Component} from "react";
import {createTheme} from "@mui/material/styles";
import {Box, Container, Paper, ThemeProvider, Typography} from "@mui/material";
import {ReactComponent as Logo} from "../../img/quill_logo.svg";
import isoLogo from "../../img/bqa-iso-27001.png";
import diossLogo from "../../img/dioss-logo.svg";
import {withTranslation} from "react-i18next";

class SessionThemedContainer extends Component {

	constructor(props) {
		super(props);
	}

	createTheme = (style) => {
		return createTheme({
			palette: {
				primary: {
					main: '#' + (style?.primaryBackgroundColor || '178A3A'),
					contrastText: '#' + (style?.primaryTextColor || 'FFFFFF')
				},
				secondary: {
					main: '#' + (style?.secondaryBackgroundColor || '000000'),
					contrastText: '#' + (style?.secondaryTextColor || 'FFFFFF')
				}
			},
			typography: {
				fontFamily: 'Lato,"Trebuchet MS",Arial,Helvetica,sans-serif'
			},
		});
	}

	render() {
		const style = this.props.style;
		return <ThemeProvider theme={this.createTheme(style)}>
			<Box sx={{
				backgroundColor: '#f2f2f2',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: 1
			}} maxWidth="false">
				<Box sx={{flexGrow: 1}}></Box>
				<Container maxWidth="sm">
					<Paper variant="outlined" sx={{
						display: 'flex',
						flexDirection: 'column',
						pt: 3,
						pb: 3,
						pl: {xs: 2, sm: 3},
						pr: {xs: 2, sm: 3},
					}}>

						{<Box sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end', borderBottom: '1px solid #EFEFEF', pb: 3, flexWrap: 'wrap'}}>
							<Logo style={{width: '100px', minWidth: '100px'}}/>
							{!!style?.logoBase64 && <img
								src={'data:image/png;base64,' + style.logoBase64}
								alt={this.props.t('company.logo')}
								style={{width: '100px'}}
							/>}
						</Box>}

						{this.props.title && <Box sx={{display: 'flex', justifyContent: 'center', mt: 2, mb: 1}}>
							<Typography component="h1" variant="h5">{this.props.title}</Typography>
						</Box>}

						{this.props.children}


					</Paper>
				</Container>
				<Box sx={{flexGrow: 1}}></Box>
				<Box sx={{maxHeight: '166px', p: 1, display: 'flex', width: '100%', justifyContent: 'space-between', gap: 9}}>
					<Box sx={{display: 'flex', margin: 0.5, flexWrap: 'no-wrap', minWidth: '150px', maxWidth: '200px', alignSelf: 'flex-end'}}>
						<img
							src={diossLogo}
							alt="Dioss Smart Solutions"
							style={{width: '100%', objectFit: 'contain'}}
						/>
					</Box>

					<Box sx={{display: 'flex', flexWrap: 'no-wrap', minWidth: '100px', maxWidth: '150px', alignSelf: 'flex-end'}}>
							<img
								src={isoLogo}
								alt="BQA ISO 27001"
								style={{width: '100%', objectFit: 'contain'}}
							/>
					</Box>
				</Box>
			</Box>

		</ThemeProvider>
	}
}

export default withTranslation()(SessionThemedContainer);

