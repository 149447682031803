import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import {Paper, Typography} from "@mui/material";
import {Redirect, withRouter} from "../common/RouterHelper";
import LoadingComponent from "../common/LoadingComponent";

class IndexComponent extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const sessionInfo = this.props.sessionInfo;

		let redirectPath = null;
		if (!!sessionInfo?.companyDocumentOverviewAccessible) {
			redirectPath = '/document/general-overview';
		} else if (!sessionInfo?.guestAccess) {
			redirectPath = '/document/signing-overview';
		} else if (!!this.props.sessionGuestPath) {
			redirectPath = this.props.sessionGuestPath;
		} else if (!!sessionInfo) {
			redirectPath = '/user/profile';
		}

		return <AppContainer needsSession>
			{!sessionInfo && <LoadingComponent/>}
			{!!redirectPath && <Redirect to={redirectPath}/>}
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				<Typography variant="h6">{this.props.t('notFound.message')}</Typography>
			</Paper>
		</AppContainer>
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionGuestPath: state.session.guestPath,
		}
	},
	dispatch => {
		return {}
	}
)(IndexComponent)));
