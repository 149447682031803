import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, Badge, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Radio, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from '@mui/icons-material/Warning';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {SIGNATURE_TYPES} from "../common/Constants";
import {SIGNER_SMS_OTP_NUMBER_ERROR} from "./EditorUtils";

export const SignerSettingsComponent = ({
											document,
											signer,
											readOnly,
											signatureTypeConfigLogos,
											onSignerChangeSignatureTypeConfig,
											onSignerChangeCapacityTitle,
											onSignerChangeUseKnownOtpNumber,
											onSignerChangeOtpNumber,
											onSignerChangeGuestExpiryDuration
										}) => {

	const {t} = useTranslation();

	const [guestExpiryDuration, setGuestExpiryDuration] = useState(null);
	const [guestExpiryDialogOpen, setGuestExpiryDialogOpen] = useState(false);
	const guestExpiryDurationError = !guestExpiryDuration || guestExpiryDuration < 10 || guestExpiryDuration > 1440;

	const onOpenGuestExpiryDialog = useCallback(() => {
		setGuestExpiryDuration(signer?.guestExpiryDuration || 10);
		setGuestExpiryDialogOpen(true);
	}, [guestExpiryDialogOpen, signer]);

	const onCloseGuestExpiryDialog = useCallback(() => {
		setGuestExpiryDialogOpen(false);
	}, [guestExpiryDialogOpen]);

	const onLocalChangeGuestExpiryDuration = useCallback((e) => {
		const parsed = parseInt(e.target.value, 10);
		if (!isNaN(parsed)) {
			setGuestExpiryDuration(parsed);
		}
	}, [signer]);

	const onResetGuestExpiryDuration = useCallback((e) => {
		onSignerChangeGuestExpiryDuration(signer.id, null);
		setGuestExpiryDialogOpen(false);
	}, [signer]);

	const onConfirmGuestExpiryDuration = useCallback((e) => {
		onSignerChangeGuestExpiryDuration(signer.id, guestExpiryDuration);
		setGuestExpiryDialogOpen(false);
	}, [signer, guestExpiryDuration]);

	const hasKnownOtpNumberOption = signer.otpNumberKnown;
	const showKnownOtpNumberOption = (!readOnly && hasKnownOtpNumberOption) || (readOnly && signer.useKnownOtpNumber);
	const showChooseOtpNumber = !readOnly || (readOnly && !signer.useKnownOtpNumber);
	const signatureTypeMap = document.enabledSignatureTypeConfigs.reduce((prev, current) => ({
		...prev,
		[current.signatureType]: [...prev[current.signatureType] || [], {...current}]
	}), {});

	return <>
		<Box sx={{display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap'}}>
			{Object.keys(SIGNATURE_TYPES)
				.filter(signatureType => !!signatureTypeMap[signatureType])
				.map(type => {
					return signatureTypeMap[type].map(config => {
						const types = (signer?.signatureTypeConfigs || []);
						const enabled = types.indexOf(config.id) >= 0;
						const disabled = (readOnly && !document?.canUpdateSigningMethods) ||
							!document?.editPermission ||
							signer.signed ||
							(enabled && types.length === 1) ||
							['SIGNED', 'DECLINED', 'CLOSED'].indexOf(signer.signRequestState) !== -1 ||
							(signer.hasSignerVerificationData && (['BELGIAN_EID', 'ITSME'].indexOf(type) === -1) || !!signer.signerVerificationData?.itsmeUserCode && type !== 'ITSME');
						return <IconButton
							key={config.id}
							size="small"
							disabled={disabled}
							title={config.label}
							onClick={() => onSignerChangeSignatureTypeConfig(signer.id, config.id, !enabled)}
						>
							<img src={!!config.logoMimeType && !!signatureTypeConfigLogos[config.id] ? `data:${config.logoMimeType};base64,${signatureTypeConfigLogos[config.id]}`: SIGNATURE_TYPES[type].img}
								 style={{width: '30px', height: 'auto', ...(!enabled && {filter: 'grayscale(100%)', opacity: 0.5})}}/>
						</IconButton>
					})
				})}
			<Box sx={{flexGrow: 1}}/>
			{!!onSignerChangeGuestExpiryDuration && signer?.person?.guest && <>
				<IconButton
					size="small"
					disabled={readOnly}
					title={t('editor.signingExpiry')}
					onClick={onOpenGuestExpiryDialog}
				>
					<Badge badgeContent={signer?.guestExpiryDuration || 0} color="success">
						<AccessAlarmIcon/>
					</Badge>
				</IconButton>
				{guestExpiryDialogOpen && <Dialog
					open
					onClose={onCloseGuestExpiryDialog}
					fullWidth
					maxWidth="sm"
				>
					<DialogTitle>{t('editor.signingExpiry')}</DialogTitle>
					<DialogContent>
						<Typography>{t('editor.signingExpiryDescription')}</Typography>
						<TextField
							fullWidth
							value={guestExpiryDuration}
							error={guestExpiryDurationError}
							helperText={t('editor.signingExpiryError')}
							type="number"
							size="small"
							inputProps={{min: 10, max: 1440}}
							onChange={onLocalChangeGuestExpiryDuration}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={onCloseGuestExpiryDialog} id="btn-guest-expiry-cancel">{t('cancel')}</Button>
						<Button
							variant="contained"
							onClick={onResetGuestExpiryDuration}
							id="btn-guest-expiry-reset"
						>
							{t('editor.signingExpiryReset')}
						</Button>
						<Button
							variant="contained"
							onClick={onConfirmGuestExpiryDuration}
							id="btn-guest-expiry-confirm"
							disabled={guestExpiryDurationError}
						>
							{t('confirm')}
						</Button>
					</DialogActions>
				</Dialog>}
			</>}
			{!!signer?.person && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Typography variant="body2">
					{t('editor.signingCapacity')}
				</Typography>
				<Autocomplete
					freeSolo
					options={signer?.person?.capacityTitles || []}
					inputValue={signer?.capacityTitle || ''}
					onInputChange={(e, newValue) => onSignerChangeCapacityTitle(signer.id, newValue)}
					renderInput={(params) => <TextField
						{...params}
						variant="outlined"
						size="small"
					/>}
					sx={{width: '200px'}}
					disabled={readOnly}
				/>
			</Box>}
		</Box>

		{signer?.signatureTypeConfigs?.some(id => document.signatureTypeConfigs.some(config => config.id === id && config.signatureType === 'SMS_OTP')) && !!signer?.person && <Box sx={{display: 'flex', flexDirection: 'column', mt: 1, mb: 1}}>
			{showKnownOtpNumberOption && <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
				<Radio
					checked={!!signer.useKnownOtpNumber}
					onChange={(e, value) => onSignerChangeUseKnownOtpNumber(signer.id, true)}
					disabled={readOnly}
				/>
				<Typography variant="body2">
					{t('editor.signingSmsOtpKnownNumber').replace('$SUFFIX', signer.otpNumberSuggestionSuffix)}
				</Typography>
			</Box>}
			{showChooseOtpNumber && <Box sx={{display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap'}}>
				{(hasKnownOtpNumberOption || readOnly) && <Radio
					checked={!signer.useKnownOtpNumber}
					onChange={(e, value) => onSignerChangeUseKnownOtpNumber(signer.id, false)}
					disabled={readOnly}
				/>}
				<Typography variant="body2" sx={{flexBasis: '200px', flexGrow: 1, flexShrink: 1}}>
					{t('editor.signingSmsOtpEnterNumber')}
				</Typography>
				<TextField
					margin="none"
					required
					autoComplete="off"
					size="small"
					value={signer.otpNumber || ''}
					onChange={e => onSignerChangeOtpNumber(signer.id, e.target.value)}
					inputProps={{maxLength: 20}}
					type="tel"
					error={!signer.useKnownOtpNumber && SIGNER_SMS_OTP_NUMBER_ERROR(signer.otpNumber)}
					sx={{flexBasis: '200px'}}
					disabled={readOnly}
				/>
			</Box>}
		</Box>}
	</>

}

export const FieldsContainer = ({readOnly, signer, fields, onFieldRemove, onFieldRemoveAll}) => {

	const {t} = useTranslation();

	return <Box>
		{fields?.length > 0 && <>
			<Box sx={{display: 'flex'}}>
				<Box sx={{
					alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1, mt: 1, maxHeight: '75px', overflow: 'auto', '&::-webkit-scrollbar': {
						width: '10px', height: '10px', background: '#F1F1F1'
					}, '&::-webkit-scrollbar-thumb': {
						background: '#C1C1C1'
					}
				}}>
					{[...(fields || [])]
						.sort((a, b) => a.pageIndex - b.pageIndex)
						.map((field, index) => <Chip key={index}
													 label={t('page') + ' ' + (field.pageIndex + 1)}
													 variant="outlined"
													 onDelete={() => onFieldRemove(signer, index)}
													 disabled={readOnly}
						/>)}
				</Box>
				<Box sx={{flexGrow: 1}}/>
				<IconButton color="primary"
							title={t('editor.signingSignatureFieldDeleteAll')}
							onClick={() => onFieldRemoveAll(signer)}
							disabled={readOnly || signer.signed}
							size="small"
							sx={{alignSelf: 'flex-start', mt: 1}}
				>
					<DeleteIcon/>
				</IconButton>
			</Box>
		</>}
	</Box>
}

export const FormFieldsContainer = ({readOnly, type, signer, onFormFieldRemove, onFormFieldRemoveAll}) => {

	const {t} = useTranslation();
	const fields = signer?.formFields?.filter(field => !type || field.type === type) || [];

	return <Box>
		{fields.length > 0 && <>
			<Box sx={{display: 'flex'}}>
				<Box sx={{
					alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1, mt: 1, maxHeight: '75px', overflow: 'auto', '&::-webkit-scrollbar': {
						width: '10px', height: '10px', background: '#F1F1F1'
					}, '&::-webkit-scrollbar-thumb': {
						background: '#C1C1C1'
					}
				}}>
					{fields
						.sort((a, b) => a.pageIndex - b.pageIndex)
						.map((field, index) => <Chip key={index}
													 icon={(!field.editable && !field.value && field.type !== 'ATTRIBUTE') ? <WarningIcon fontSize="small"/> : undefined}
													 label={field.type === 'ATTRIBUTE' && field.attributeType !== 'META_FIELD' ? t('editor.formFieldAttributeType_' + field.attributeType) : field.name}
													 variant="outlined"
													 onDelete={() => onFormFieldRemove(field)}
													 disabled={readOnly}
						/>)}
				</Box>
				<Box sx={{flexGrow: 1}}/>
				<IconButton color="primary"
							title={t('editor.signingSignatureFieldDeleteAll')}
							onClick={() => onFormFieldRemoveAll(signer, type)}
							disabled={readOnly || signer.signed}
							size="small"
							sx={{alignSelf: 'flex-start', mt: 1}}
				>
					<DeleteIcon/>
				</IconButton>
			</Box>
		</>}
	</Box>
}

